import { Controller } from "stimulus";

export default class extends Controller {
  selectableElementTypes() {
    return ['a', 'label', 'button', 'input'];
  }

  connect() {
    this.element.addEventListener('click', event => {
      if (!this.selectableElement(event) && this.firstSelectableElementInRow(event)) {
        this.firstSelectableElementInRow(event).click();
      }
    });
  }

  selectableElement(event) {
    const elementType = event.target.nodeName.toLowerCase();

    return this.selectableElementTypes().indexOf(elementType) > -1;
  }

  firstSelectableElementInRow(event) {
    const row = event.target.closest('tr');
    
    return row.querySelector(this.selectableElementTypes().join(','));
  }
}
