import React from "react";

import Loader from "./Loader";
import ItemGroup from "./ItemGroup";

const ItemMeta = ({ option }) => {
  return <span>{option.sellable_products_count}</span>;
};

const ProductTypes = ({ productTypes, ...props }) => {
  return (
    <ItemGroup
      title="Product types"
      name="product_type"
      options={productTypes}
      {...props}
    >
      <ItemMeta />
    </ItemGroup>
  );
};

export default (props) => {
  if (props.productTypes) return <ProductTypes {...props} />;

  return (
    <Loader url="/product_types.json?sellable=true" as="productTypes">
      <ProductTypes {...props} />
    </Loader>
  );
};
