import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['timeField', 'hour', 'minutes', 'period'];

  connect() {
    this.periodTarget.innerHTML = this.period();
  }

  update() {
    this.timeFieldTarget.value = `${this.hour()}:${this.minutes()}`;
    
    this.periodTarget.innerHTML = this.period();
  }

  period() {
    if (!this.hour()) return "";

    return this.hour() < 12 ? "AM" : "PM";
  }

  hour() { return this.hourTarget.value }

  minutes() { return this.minutesTarget.value }
}
