import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["focusField"];

  show(popupName) {
    document.querySelector(`[data-popup="${popupName}"]`).classList.remove("hidden");
    if(this.hasFocusFieldTarget) this.focusFieldTarget.focus();
  }

  open(event) {
    const popupName = event.target.getAttribute("data-popup-name");

    this.show(popupName);
  }

  close(event) {
    const popupName = event.target.getAttribute("data-popup-name");

    document.querySelector(`[data-popup="${popupName}"]`).classList.add("hidden");
  }

  load(event) {
    event.preventDefault();

    let jsUrl = event.target.closest("a").href;

    if (jsUrl.indexOf('?') > -1) {
      jsUrl = jsUrl.replace('?', '.js?');
    } else {
      jsUrl += '.js';
    }

    fetch(jsUrl)
      .then(response => {
        if (response.status === 401 && response.statusText === 'Unauthorized'){
          window.location.reload();
          return Promise.reject();
        } else {
          return response.text()
        }
      })
      .then(html => {
        // this.show('loaded-modal', html);\
        document.querySelector('#popup-body-loaded-modal').innerHTML = html;
        this.show('loaded-modal');
      });
  }
}
