import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["item", "content"];

  toggle(event) {
    const currentItem = event.currentTarget.closest('[data-accordion-target="item"]');
    const currentContent = currentItem.querySelector('[data-accordion-target="content"]');
    const currentIcon = currentItem.querySelector('[data-accordion-target="icon"]');

    this.itemTargets.forEach((item) => {
      if (item !== currentItem) {
        item.querySelector('[data-accordion-target="content"]').classList.add("hidden");
        item.querySelector('[data-accordion-target="icon"]').classList.remove("fa-chevron-up");
        item.querySelector('[data-accordion-target="icon"]').classList.add("fa-chevron-down");
      }
    });

    currentContent.classList.toggle("hidden");
    currentIcon.classList.toggle("fa-chevron-up");
    currentIcon.classList.toggle("fa-chevron-down");
  }
}
