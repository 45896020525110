import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["clock"];
  static values = { datetime: String };

  connect() {
    this.startTime = Date.parse(this.datetimeValue);
    this.tick = setInterval(this.tick.bind(this), 1000);
  }
  disconnect() {
    clearInterval(this.tick);
  }

  tick() {
    const duration = this._getDuration({
      start: this.startTime,
      end: new Date(),
    });

    this.updateClock(duration);
  }

  updateClock(duration) {
    this.clockTarget.innerHTML = this.renderClock(duration);
  }

  renderClock({ hours, minutes, seconds }) {
    return `${this.renderHours(hours)}${this.renderMinutes(minutes)}${this.renderSeconds(seconds)}`;
  }

  renderHours(hours) {
    if(hours <= 0) { return "" }

    return `${hours}:`
  }

  renderMinutes(minutes) {
    return `${minutes.toString().padStart(2, "0")}:`
  }

  renderSeconds(seconds) {
    return `${seconds.toString().padStart(2, "0")}`
  }

  _getDuration({ start, end }) {
    const elapsedMilliseconds = end - start;
    const hours = Math.floor(elapsedMilliseconds / 3600000);
    const minutes = Math.floor((elapsedMilliseconds % 3600000) / 60000);
    const seconds = Math.floor((elapsedMilliseconds % 60000) / 1000);

    return { hours, minutes, seconds };
  }
}
