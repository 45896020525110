import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['startDate', 'startPM', 'endDate', 'endDateContainer'];

  setStartDate(event) {
    if (event.target.value) {
      const dayAfter = new Date(event.target.value);
      dayAfter.setDate(dayAfter.getDate() + 1);

      this.endDateTarget._flatpickr.set('minDate', dayAfter);
      this.endDateContainerTarget.classList.remove('hidden');
    }
  }

  setEndDate(event) {
    if (event.target.value) {
      const dayBefore = new Date(event.target.value);
      dayBefore.setDate(dayBefore.getDate() - 1);

      this.startDateTarget._flatpickr.set('maxDate', dayBefore);

      this.startPMTarget.checked = true;
      this.startPMTarget.disabled = true;
    } else {
      this.startPMTarget.disabled = false;
    }
  }
}
