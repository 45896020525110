import React from "react";

import ItemGroup from "./ItemGroup";

export const formatPrice = price => `$${parseFloat(price).toFixed(2)}`;

export const displayMeasurementType = type => type === "SF" ? "sq. ft" : ""

const ItemMeta = ({ product, option }) => {
  const measurementType = displayMeasurementType(product.measurement_type);

  return (
    <span>
      {formatPrice(option.price)}
      {measurementType ? ` / ${measurementType}` : null}
    </span>
  );
};

const ProductVariants = ({ product, variants, ...props }) => {
  return (
    <ItemGroup
      title="Product variants"
      name="estimate_item[product_variant_id]"
      options={variants || product.variants}
      {...props}
    >
      <ItemMeta product={product} />
    </ItemGroup>
  );
};

export default ProductVariants;
