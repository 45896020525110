import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "repAssignForm"]

  submitWithIndex(event) {
    event.preventDefault();
    const form = event.target.closest("form");

    const index = Array.from(this.element.querySelectorAll("form")).indexOf(form);

    // Create a hidden input to include the selection_rank in the form submission
    const indexInput = document.createElement("input");
    indexInput.type = "hidden";
    indexInput.name = "appointment_issue[selection_rank]";
    indexInput.value = index + 1;

    form.appendChild(indexInput);
    form.submit();
  }
}
