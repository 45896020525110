import React from "react";

import ItemGroup from "./ItemGroup";

import { formatPrice } from "./ProductVariants";

const ItemMeta = ({ option }) => {
  return (
    <span>
      {formatPrice(option.price)}
    </span>
  );
};

const ProductOptions = ({ product, onChange, ...props }) => {
  return product.option_groups.map((option_group, index) => {
    return (
      <ItemGroup
        title={option_group.name}
        name={`estimate_item[estimate_item_options_attributes][${index}][option_id]`}
        options={option_group.options}
        onChange={option => onChange(option_group, option)}
        {...props}
      >
        <ItemMeta product={product} />
      </ItemGroup>
    );
  });
};

export default ProductOptions;
