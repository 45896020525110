import { Controller } from "stimulus";

// NGL, this is a bit hacky
// The problem is that we want installs which span multiple days to be in the same predictable position each day
// so that each day you can easily see the difference between new and continuing installs
// The theory to the solution is as we loop over the calendar to keep track of yesterday's installs, and then make
// sure that any installs from yesterday that are also today are in the same position today.
export default class extends Controller {
  installPositions = []

  connect() {
    // ok so there's something weird and turboy (maybe) going on here
    // when returning to this page, like clicking back from an install to the calendar
    // all of the page is already correct, and running any of this code makes it bad
    // so we check for any blank installs, which only this code creates, and leave if there are any
    if (this.element.querySelectorAll(".blankInstall").length > 0) return;

    const cells = this.element.querySelectorAll(".calendar-day");

    cells.forEach(cell => {
      const installs = Array.from(cell.querySelectorAll("[data-entry-id]"));
      const todayInstallIds = installs.map(install => install.getAttribute("data-entry-id"));
      let movedInstalls = 0;

      installs.forEach(install => {
        this.hideSubsequentInstallsWithSameId(install);
      });

      if (this.installPositions.length === 0 || todayInstallIds.length === 0) {
        this.installPositions = todayInstallIds;
        return;
      }

      const moveInstall = install => {
        cell.querySelector(".ordered-installs").appendChild(install)
        movedInstalls++
      };

      // loop over yesterdays installs
      this.installPositions.forEach(installId => {
        // if install also exists today
        if (todayInstallIds.indexOf(installId) > -1) {
          // move today's install to that position
          moveInstall(cell.querySelector(`[data-entry-id="${installId}"]`))
        } else {
          // otherwise move a new install into this position
          const newInstall = cell.querySelector(".installs [data-entry-new]");
          if (newInstall) {
            moveInstall(newInstall)
          } else if (movedInstalls < todayInstallIds.length) {
            // or if we haven't yet moved all of today's installs
            // create a blank install at that position
            const blankInstall = document.createElement("div");
            blankInstall.className = "calendar-entry blankInstall";
            cell.querySelector(".ordered-installs").appendChild(blankInstall);
          }
        }
      });

      this.installPositions = Array.from(cell.querySelectorAll(".calendar-entry")).map(install => install.getAttribute("data-entry-id"));
    });
  }

  hideSubsequentInstallsWithSameId(element) {
    const installId = element.getAttribute("data-entry-id");
    const installs = Array.from(this.element.querySelectorAll(`[data-entry-id="${installId}"]`));
    const currentIndex = installs.indexOf(element);

    installs.slice(currentIndex + 1).forEach(install => {
      install.classList.add("subsequentInstall");
      install.style.width = "100%";
    });
  }
}
