import { Controller } from "stimulus";
import { handleRequiredFields } from "./concerns/handle_required_fields";

export default class extends Controller {
  static targets = ["reasons"];
  static values = {
    appointmentAt: String,
    appointmentCreated: String
  };

  change(event) {
    if (this.overFifteenMinutesOld()) {
      this.reasonsTarget.classList.toggle("hidden", this.datesAreTheSame(event.target.value, this.appointmentAtValue));
    }

    handleRequiredFields(this.reasonsTarget);
  }

  datesAreTheSame(date1, date2) {
    return new Date(date1).toLocaleDateString() ===  new Date(date2).toLocaleDateString()
  }

  overFifteenMinutesOld() {
    const appointmentCreated = new Date(this.appointmentCreatedValue);
    const now = new Date();
    const difference = now - appointmentCreated;

    const minutes = difference / 1000 / 60;

    return minutes > 15;
  }
}
