import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["password", "confirmPassword", "matchMessage", "lengthMessage", "submitButton"];
  static values = {
    minimumLength: Number,
  };

  checkPasswordMatch() {
    const password = this.passwordTarget.value;
    const confirmPassword = this.confirmPasswordTarget.value;

    const isMatch = password === confirmPassword;
    const isTooShort = password.length < this.minimumLengthValue;

    if (password && confirmPassword) {
      this.updateMessages(isMatch, isTooShort);
      this.toggleSubmitButton(isMatch && !isTooShort);
    } else {
      this.clearMessages();
      this.disableSubmitButton();
    }
  }

  updateMessages(isMatch, isTooShort) {
    this.updateMatchMessage(isMatch);
    this.updateLengthMessage(isTooShort);
  }

  updateMatchMessage(isMatch) {
    this.matchMessageTarget.textContent = isMatch ? "" : "Passwords do not match!";
  }

  updateLengthMessage(isTooShort) {
    this.lengthMessageTarget.textContent = isTooShort ? `Password needs to be at least ${this.minimumLengthValue} characters` : "";
  }

  clearMessages() {
    this.matchMessageTarget.textContent = "";
    this.lengthMessageTarget.textContent = "";
  }

  toggleSubmitButton(enable) {
    enable ? this.submitButtonTarget.removeAttribute("disabled") : this.disableSubmitButton();
  }

  disableSubmitButton() {
    this.submitButtonTarget.setAttribute("disabled", true);
  }
}
