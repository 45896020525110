import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["summary", "whole"];

  open() {
    this.summaryTarget.classList.add("hidden");
    this.wholeTarget.classList.remove("hidden");
  }

  close() {
    this.summaryTarget.classList.remove("hidden");
    this.wholeTarget.classList.add("hidden");
  }
}
