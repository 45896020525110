import { Controller } from "stimulus";

export default class extends Controller {
  static values = {
    selector: String
  }

  connect() {
    const $targetElement = this.element.querySelector(this.selectorValue);

    if (!$targetElement) return;

    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView#block
    $targetElement.scrollIntoView({ inline: "center", block: "nearest" });
  }
}
