import React, { useState } from "react";

import ProductTypes from "./ProductTypes";
import ProductStyles from "./ProductStyles";
import Products from "./Products";
import ProductVariants, { formatPrice, displayMeasurementType } from "./ProductVariants";
import ProductOptions from "./ProductOptions";

const ProductCatalogue = ({ productTypes }) => {
  const [productType, setProductType] = useState();
  const [productStyle, setProductStyle] = useState();
  const [product, setProduct] = useState();
  const [productVariant, setProductVariant] = useState();
  const [productOptions, setProductOptions] = useState({});
  const [quantity, setQuantity] = useState(1);
  let totalCost = 0;
  
  const selectedCorrectNumberOfOptions = productVariant ? Object.keys(productOptions).length === product.option_groups.length : false;

  if (selectedCorrectNumberOfOptions) {
    totalCost = parseFloat(productVariant.price);

    Object.keys(productOptions).forEach(key => {
      totalCost += parseFloat(productOptions[key]);
    });
  }

  return (
    <div>
      <ProductTypes current={!productType} productTypes={productTypes} onChange={option => {
        setProductType(option);
        setProductStyle();
        setProduct();
        setProductVariant();
        setQuantity(1);
      }} />

      {productType && (
        <ProductStyles current={!productStyle} productType={productType} onChange={option => {
          setProductStyle(option);
          setProduct();
          setProductVariant();
          setQuantity(1);
        }} />
      )}

      {productStyle && (
        <Products current={!product} productStyle={productStyle} onChange={option => {
          setProduct(option);
          setProductVariant();
          setProductOptions({});
          setQuantity(1);
        }} />
      )}

      {product && (
        <ProductVariants current={!productVariant} product={product} onChange={option => {
          setProductVariant(option);
        }} />
      )}

      {productVariant && product.option_groups.length > 0 && (
        <ProductOptions current={!selectedCorrectNumberOfOptions} product={product} onChange={(optionGroup, option) => {
          setProductOptions({
            ...productOptions,
            [optionGroup.id]: option.price
          });
        }} />
      )}

      {selectedCorrectNumberOfOptions && (<>
        <div className="mb-8">
          <h3 className="h4 mb-3">Quantity</h3>

          <input type="number" name="estimate_item[quantity]" value={quantity} min="1" className="form-control w-1/2 inline" onChange={event => {
            const newQuantity = event.target.value;

            if (newQuantity === "" || (!isNaN(newQuantity) && newQuantity > 0)) {
              setQuantity(newQuantity);
            } else {
              setQuantity(1);
            }
          }} />
          {displayMeasurementType(product.measurement_type) && <span className="ml-2">{displayMeasurementType(product.measurement_type)}</span>}
          <span className="mx-4">=</span>
          <span className="h4">{formatPrice(totalCost * quantity)}</span>
        </div>

        <div className="mb-2">
          <h3 className="h4 mb-3">Additional information</h3>

          <textarea name="estimate_item[additional_information]" className="form-control" row="10"></textarea>
        </div>

        <button className="btn btn-primary mt-4">Create Estimate Item</button>
      </>)}
    </div>
  );
};

export default ProductCatalogue;
