// Reconnect ActionCable after switching accounts

import { Controller } from "stimulus"

export default class extends Controller {
  submit() {
    // https://stackoverflow.com/a/69537709
    this.element.requestSubmit();
  }
}
