import { Controller } from "stimulus";

// TODO: This replicates the logic in the application, if you are editing this file,
// considiering adding an endpoint that uses OpsBusinessDaysCalculator to do the
// calculations and return it to this Stimulus Controller. [AD]

export default class extends Controller {
  static targets = ["startDate", "numberOfDays", "completedDate", "completionTime", "estimatedDate", "numberOfBusinessDays"];

  calculatedEndDate() {
    if (!this.hasNumberOfDaysTarget) {
      return
    }

    const oneDay = 24 * 60 * 60 * 1000;
    let currentDate = new Date(this.startDateTarget.value);
    let numberOfDays = this.numberOfDaysTarget.value;
    let count = 1;

    while (count < numberOfDays) {
      currentDate = new Date(currentDate.getTime() + oneDay);
      if (currentDate.getDay() !== 0) {
        count++;
      }
    }

    this.completedDateTarget.innerHTML = currentDate.toLocaleDateString("en-US");
  }

  numberOfDays() {
    if (!this.hasEstimatedDateTarget) {
      return
    }

    const startDate = new Date(this.startDateTarget.value);
    const endDate = new Date(this.estimatedDateTarget.value);
    let count = 0;

    for (let currentDate = new Date(startDate); currentDate < endDate; currentDate.setDate(currentDate.getDate() + 1)) {
      if (currentDate.getDay() !== 0) {
        count++;
      }
    }

    if (endDate.getDay() !== 0) {
      count++;
    }

    if(this.numberOfBusinessDaysTarget) {
      this.numberOfBusinessDaysTarget.innerHTML = count;
    }

    if(this.completionTimeTarget.value) {
      this.completionTimeTarget.value = count;
    }
  }
}
