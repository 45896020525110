import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["timeSinceAssignment", "timeTillAppointment"];

  static values = {
    appointmentAt: String,
    assignedAt: String,
    appointmentId: String
  };

  updateTimes(appointmentAtValue, assignedAtValue) {
    this.interval = setInterval(() => {
      const timeSince = new Date().getTime() - assignedAtValue 
      const timeTill = appointmentAtValue - new Date().getTime()

      this.timeSinceAssignmentTarget.innerHTML = `${this.hoursFromTime(timeSince)}h : ${this.minutesFromTime(timeSince)}m`
      this.timeTillAppointmentTarget.innerHTML = `${this.hoursFromTime(timeTill)}h : ${this.minutesFromTime(timeTill)}m`
    }, 1000)
  }

  hoursFromTime(time) {
    return Math.floor(time / (1000 * 60 * 60))
  }

  minutesFromTime(time) {
    return Math.floor((time % (1000 * 60 * 60)) / (1000 * 60))
  }

  connect() {
    const appointmentAtValue = new Date(this.appointmentAtValue).getTime()
    const assignedAtValue = new Date(this.assignedAtValue).getTime()
    
    this.updateTimes(appointmentAtValue, assignedAtValue)
  }

  disconnect() {
    clearInterval(this.interval)
  }
}
