import React from "react";

import Loader from "./Loader";
import ItemGroup from "./ItemGroup";

const ItemMeta = ({ option }) => {
  return <span>{option.sellable_products_count}</span>;
};

const ProductStyles = ({ productStyles, ...props }) => {
  return (
    <ItemGroup
      title="Product styles"
      name="product_style"
      options={productStyles}
      {...props}
    >
      <ItemMeta />
    </ItemGroup>
  );
};

export default (props) => {
  if (props.productStyles) return <ProductStyles {...props} />;

  if (!props.productType) return null;

  return (
    <Loader url={`/product_types/${props.productType.id}/product_styles.json?sellable=true`} as="productStyles">
      <ProductStyles {...props} />
    </Loader>
  );
};
