import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['appointmentAt', 'date', 'hour', 'minutes', 'period'];

  update() {
    this.appointmentAtTarget.value = `${this.date()} ${this.hour()}:${this.minutes()}`;
    
    this.periodTarget.innerHTML = this.period();
  }

  period() {
    if (!this.hour()) return "";

    return this.hour() < 12 ? "AM" : "PM";
  }

  date() { return this.dateTarget.value }

  hour() { return this.hourTarget.value }

  minutes() { return this.minutesTarget.value }
}