import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tiers", "tier"]

  // make sure we show the tiers when selecting a service
  // make sure we hide and disable the tiers when unselecting a service
  serviceChange(event) {
    const $serviceCheckbox = event.target

    if ($serviceCheckbox.checked) {
      this.tiersTarget.classList.remove("hidden")
      this.tierTargets.forEach(tier => tier.removeAttribute("disabled"))
    } else {
      this.tiersTarget.classList.add("hidden")
      this.tierTargets.forEach(tier => tier.setAttribute("disabled", true))
    }
  }

  // prevent more than one tier checkbox being checked in this service
  tierChange(event) {
    const $tierCheckbox = event.target

    if ($tierCheckbox.checked) {
      this.tierTargets.forEach(tier => tier.checked = false)
      $tierCheckbox.checked = true
    }
  }
}
