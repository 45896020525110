import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["source", "response"];
  static values = {
    copy: String,
  }

  copy() {
    navigator.clipboard.writeText(this.value());
    this.responseTarget.innerText = "Copied!";
  }

  value() {
    if (this.hasSourceTarget && this.sourceTarget.value) {
      return this.sourceTarget.value
    } else {
      return this.copyValue
    }
  }
}
