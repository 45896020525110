import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ['formSubmit', 'date'];

  update() {
    this.formSubmitTarget.setAttribute("href", `/projects/ready_for_install?date=${this.date()}`)
  }

  date() { return this.dateTarget.value }
}