import React from "react";

const ensureSlashesAreSpaced = string => string.replace(/\s?\/\s?/, " / ");

const ItemGroup = ({ title, name, options, onChange = () => {}, current, children }) => {
  if (current && options.length === 1) {
    onChange(options[0]);
  }

  return (<>
    <h2>{title}</h2>
    
    <ul className="options">
      {options.map(option => (
        <li key={option.id} className="option">
          <label className="option__label">
            <input name={name} type="radio" defaultChecked={options.length === 1} value={option.id} onChange={event => onChange(option)} />
            
            <h4 className="h5 mt-auto">{ensureSlashesAreSpaced(option.name)}</h4>

            <div className="text-primary">
              {React.Children.map(children, child => React.cloneElement(child, { option }))}
            </div>
          </label>
        </li>
      ))}
    </ul>
  </>)
};

export default ItemGroup;
