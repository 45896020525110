import { Controller } from "stimulus"

/**
 * allows a select box to toggle the display of content areas associated with its values
 */
export default class extends Controller {
  static targets = ["select"]

  /**
   * disables all required fields within a content area
   */
  disableAllRequiredFields($target) {
    Array.from($target.querySelectorAll("[required]")).forEach($element => $element.setAttribute("disabled", true))
  }

  /**
   * enables all required fields within a content area
   */
  enableAllRequiredFields($target) {
    Array.from($target.querySelectorAll("[required]")).forEach($element => $element.removeAttribute("disabled"))
  }

  /**
   * hides all possible content areas
   */
  hideAllContents() {
    const $targets = this.element.querySelectorAll(`[data-toggle-fields-select-target]`)
    
    if ($targets.length === 0) return

    const withoutSelect = $target => $target !== this.selectTarget
    const hideAndDisableTarget = $target => {
      $target.classList.add("hidden")
      this.disableAllRequiredFields($target)
    }
    
    Array.from($targets).filter(withoutSelect).forEach(hideAndDisableTarget)
  }

  /**
   * shows the content area associated with the selected value
   */
  showSelectedContent() {
    const $target = this.element.querySelector(`[data-toggle-fields-select-target="${this.selectTarget.value}"]`)

    if (!$target) return

    $target.classList.remove("hidden")
    this.enableAllRequiredFields($target)
  }

  toggle() {
    this.hideAllContents()
    this.showSelectedContent()
  }

  initialize() {
    this.toggle()
  }
}
