import { Controller } from "stimulus"

export default class extends Controller {
  /**
   * disables all required and .disabled-if-hidden fields within a content area
   * .disabled-if-hidden applied for repeated and mutually exclusive fields. And the hidden one should be disabled
   */
  disableAllRequiredFields($target) {
    Array.from($target.querySelectorAll("[required], .disabled-if-hidden")).forEach($element => $element.setAttribute("disabled", true))
  }

  /**
   * enables all required and .disabled-if-hidden fields within a content area
   */
  enableAllRequiredFields($target) {
    Array.from($target.querySelectorAll("[required], .disabled-if-hidden")).forEach($element => $element.removeAttribute("disabled"))
  }

  /** 
   * Hides each form section other than the one selected
   */
  hideNonSelectedFields(selectedFieldValue) {
    const fieldsToHide = this.element.querySelectorAll(`[data-toggle-fields-radio-target]:not( [data-toggle-fields-radio-target="${selectedFieldValue}"] )`)
    Array.from(fieldsToHide).forEach($element => {
      $element.classList.add('hidden')
      this.disableAllRequiredFields($element)
    })
  }

  formatSecondaryInputs(selectedFieldValue) {
    const disabledLabelClasses = ["border-disabled", "bg-disabled", "text-secondary"]
    
    Array.from(this.element.querySelectorAll(`[data-controller="secondary-service-types"] input[type="checkbox"]:not([value="${selectedFieldValue}"])`)).forEach($element => {
      $element.removeAttribute("disabled")
      $element.checked = false
      $element.closest("label").classList.remove(...disabledLabelClasses)
    })

    const secondaryToDisable = this.element.querySelector(`[data-controller="secondary-service-types"] input[type="checkbox"][value="${selectedFieldValue}"]`)

    if (!secondaryToDisable) return;

    secondaryToDisable.setAttribute("disabled", true)
    secondaryToDisable.checked = false
    secondaryToDisable.closest("label").classList.add(...disabledLabelClasses)
  }

  toggle(event) {
    const target = event.target.value;
    const $target = this.element.querySelector(`[data-toggle-fields-radio-target="${target}"]`);

    this.hideNonSelectedFields(target)
    this.formatSecondaryInputs(target)
    
    if (!$target) return;

    $target.classList.toggle('hidden', !event.target.checked);

    if($target.classList.contains('hidden')) {
      this.disableAllRequiredFields($target)
    } else {
      this.enableAllRequiredFields($target)
    };
  }

  connect() {
    const $targets = this.element.querySelectorAll(`[data-toggle-fields-radio-target]`);

    Array.from($targets).forEach($target => {
      if($target.classList.contains('hidden')) {
        this.disableAllRequiredFields($target);
      }
    });
  }
}
