import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["productType", "productStyle", "product"];

  connect() {
    this.productTypeTarget.querySelector("option").selected = true;
    this.productStyleTarget.querySelector("option").selected = true;
    this.productTarget.querySelector("option").selected = true;
    this.productStyleTarget.disabled = true;
    this.productTarget.disabled = true;
  }

  filterProductStyles() {
    const productTypeId = this.productTypeTarget.value;

    if (productTypeId) {
      this.productStyleTarget
        .querySelectorAll(`optgroup`)
        .forEach((optgroup) => (optgroup.style.display = "none"));

      this.productStyleTarget.querySelector(
        `optgroup[label="${productTypeId}"]`
      ).style.display = "block";

      this.productStyleTarget.disabled = false;
    }
  }

  filterProducts() {
    const productStyleId = this.productStyleTarget.value;
    console.log("filterProducts", this.productStyleTarget.value)

    if (productStyleId) {
      this.productTarget
        .querySelectorAll(`optgroup`)
        .forEach((optgroup) => (optgroup.style.display = "none"));

      this.productTarget.querySelector(
        `optgroup[label="${productStyleId}"]`
      ).style.display = "block";

      this.productTarget.disabled = false;
    }
  }
}
