import { Controller } from "stimulus"

const OVERLAY_ID = "fab-overlay";
const FAB_OPEN_ID = "fab-open";
const OVERLAY_CLASS = "fixed inset-0 w-screen h-screen bg-black bg-opacity-30 z-40";

export default class extends Controller {
  static targets = ["items"]

  connect() {
    this.fabCheckbox = document.getElementById(FAB_OPEN_ID);
  }

  toggle(event) {
    event.target.checked ? this.showFab() : this.dismissFab();
  }

  showFab() {
    if (!this.fabCheckbox) return;
    this.fabCheckbox.checked = true;
    this.createOverlay();
    this.toggleScroll(false);
    this.toggleItems(true);
  }

  dismissFab() {
    if (!this.fabCheckbox) return;
    this.fabCheckbox.checked = false;
    this.removeOverlay();
    this.toggleScroll(true);
    this.toggleItems(false);
  }

  createOverlay() {
    const overlay = document.createElement("div");
    overlay.id = OVERLAY_ID;
    overlay.className = OVERLAY_CLASS;
    overlay.addEventListener("click", this.dismissFab.bind(this));
    document.body.appendChild(overlay);
  }

  removeOverlay() {
    const overlay = document.getElementById(OVERLAY_ID);
    overlay?.remove();
  }

  toggleScroll(enable) {
    document.documentElement.classList.toggle("overflow-hidden", !enable);
  }

  toggleItems(show) {
    this.itemsTarget.classList.toggle('translate-x-0', show);
    this.itemsTarget.classList.toggle('translate-x-full', !show);
  }
}
