// For the automatic selection of text in a form field to enable fast updating

import { Controller } from "stimulus"

export default class extends Controller {
  select(event) {
    event.target.select()
    event.target.focus()
  }
}
