import React from "react";

import Loader from "./Loader";
import ItemGroup from "./ItemGroup";

const ItemMeta = ({ option }) => {
  return <span>{option.variants.length}</span>;
};

const Products = ({ products, ...props }) => {
  return (
    <ItemGroup
      title="Products"
      name="product"
      options={products}
      {...props}
    >
      <ItemMeta />
    </ItemGroup>
  );
};

export default (props) => {
  if (props.products) return <Products {...props} />;

  if (!props.productStyle) return null;

  return (
    <Loader url={`/product_styles/${props.productStyle.id}/products.json?sellable=true`} as="products">
      <Products {...props} />
    </Loader>
  );
};
