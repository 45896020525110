import { Controller } from "stimulus";
import { get } from "@rails/request.js";

export default class extends Controller {
  checkZipcode(event) {
    const zipcode = event.target.value;
    if (zipcode.length !== 5) {
      return;
    }

    get(`/configuration/zipcodes/${zipcode}/check`, { responseKind: "turbo-stream" });
  }
}
