import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["longitude", "latitude"];

  createWithLocation(event) {
    event.target.disabled = true
    event.target.innerText = "Finding you..."

    const success = ({ coords }) => {
      this.longitudeTarget.value = coords.longitude;
      this.latitudeTarget.value = coords.latitude;

      this.element.requestSubmit();
    }

    const error = (geolocationError) => {
      if (geolocationError instanceof GeolocationPositionError) {
        switch (geolocationError.code) {
        case 1: alert("You need to allow Orange to access your location")
        }
      }

      // whatever the error, we should create the DoorKnock and continue
      this.element.requestSubmit();
    }

    navigator.geolocation.getCurrentPosition(success, error)
  }
}
