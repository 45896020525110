import React from "react";
import useSWR from "swr";

class LoaderCache {
  constructor() {
    this.cache = {};

    document.addEventListener('turbo:load', () => {
      this.cache = {};
    });
  }

  set(url, data) {
    this.cache[this.getKey(url)] = data;
  }

  get(url) {
    return this.cache[this.getKey(url)];
  }

  has(url) {
    return this.cache.hasOwnProperty(this.getKey(url));
  }

  getKey(url) {
    // without query params
    return url.split('?')[0];
  }
}

const cache = new LoaderCache();

const fetcher = (...args) => fetch(...args).then((res) => res.json());

const Loader = ({ url, as = "data", children }) => {
  let { data, error } = useSWR(url, fetcher, { revalidateOnFocus: false });

  if (cache.has(url) && !data) {
    data = cache.get(url);
  } else {
    if (error) return <div>Error</div>; // TODO: do something sensible here
    if (!data) return <div>Loading...</div>;

    cache.set(url, data);
  }

  return React.Children.map(children, child => React.cloneElement(child, { [as]: data }));
};

export default Loader;
