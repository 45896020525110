import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    if (typeof tsParticles === 'undefined') return;

    tsParticles.load("tsparticles", {
      emitters: [
        {
          startCount: 100,
          life: {
            duration: 3,
            count: 1,
          },
          position: {
            x: 0,
            y: 10,
          },
          particles: {
            color: {
              value: ["#F16022", "#fff"],
            },
            move: {
              direction: "top-right",
            },
          },
        },
        {
          startCount: 100,
          life: {
            duration: 3,
            count: 1,
          },
          position: {
            x: 100,
            y: 10,
          },
          particles: {
            color: {
              value: ["#F16022", "#fff"],
            },
            move: {
              direction: "top-left",
            },
          },
        },
      ],
      preset: "confetti",
    });
  }
}
