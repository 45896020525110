import { Controller } from "stimulus"
import { handleRequiredFields } from "./concerns/handle_required_fields";

export default class extends Controller {
  static targets =["hiddenFields"]

  toggle(event) {
    this.hiddenFieldsTargets.forEach($target => {

      const checkboxes = $target.dataset.for.split(',').map(name => name.trim());
      const isVisible = checkboxes.some(name => {

        const checkbox = document.querySelector(`[name="${name}"][type="checkbox"]`);

        return checkbox ? checkbox.checked : false;
      });

      $target.classList.toggle('hidden', !isVisible);

      handleRequiredFields($target);
    });
  }

  connect() {
    Array.from(this.hiddenFieldsTargets).forEach(handleRequiredFields);
  }
}
