// https://github.com/hotwired/turbo/issues/109

import { Controller } from "stimulus"

export default class extends Controller {
  sorting = false

  initialize() {
    this.element.addEventListener('DOMNodeInserted', event => {
      if (this.sorting) return;

      if (event.target.parentNode === this.element) {
        this.sortChildren();
      }
    });
  }

  sortChildren() {
    const children = Array.from(this.element.children);
    
    if (elementsAreSorted(children)) return;

    this.sorting = true;

    children.sort(compareElements).forEach(child => this.element.append(child))

    this.sorting = false;
  }
}

function elementsAreSorted([ left, ...rights ]) {
  for (const right of rights) {
    if (compareElements(left, right) > 0) return false
    left = right
  }
  return true
}

function compareElements(left, right) {
  return getSortCode(left) - getSortCode(right)
}

function getSortCode(element) {
  return element.getAttribute("data-sort-code") || 0
}