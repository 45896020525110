/**
 * disables all required fields within a content area
 */
const disableAllRequiredFields = ($target) => {
    Array.from($target.querySelectorAll("[required]")).forEach($element => $element.setAttribute("disabled", true))
}

/**
 * enables all required fields within a content area
 */
const enableAllRequiredFields = ($target) => {
    Array.from($target.querySelectorAll("[required]")).forEach($element => $element.removeAttribute("disabled"))
}

export const handleRequiredFields = ($target) => {
    if($target.classList.contains('hidden')) {
        disableAllRequiredFields($target)
    } else {
        enableAllRequiredFields($target)
    }
}