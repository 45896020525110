import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["showButton", "form", "field"];

  showForm() {
    this.showButtonTarget.classList.add("hidden");
    this.formTarget.classList.remove("hidden");
    this.fieldTarget.focus();
  }
}
